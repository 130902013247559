<template>
  <div class="card-container mb-0">
    <v-card-title class="pa-0 d-flex align-center">
      <div class="biz-area-table-title">
        <b>商圏エリア別シェア獲得率比較（β）</b>
        <AlertTooltip v-if="props.alertAreaNames.length || tableAreasAlert">
          <p v-if="tableAreasAlert" :class="{ 'mb-0': !props.alertAreaNames.length }">
            ・取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。
          </p>
          <p v-if="props.alertAreaNames" class="mb-0">
            ・次の地域は世帯数が少ないため、プライバシーに配慮し非表示としています:
            <span class="font-bold">{{ props.alertAreaNames.join(', ') }}</span>
          </p>
        </AlertTooltip>
      </div>
      <v-spacer />
      <ChartDescriptionTooltip
        menu-key="chainCompare"
        sub-menu-key="bizArea"
        chart-key="comparisonOfShare"
        class="mr-27px"
      />
      <DownloadButton
        label="CSVデータ"
        :disabled="props.selectedChainIds.length === 0"
        :get-file-id="clikDownloadBizAreaComparisonTable"
        :csv-name="`チェーン比較_商圏ランキング_${convertSlashDelimiter(
          startMonth
        )}-${convertSlashDelimiter(endMonth)}`"
      />
    </v-card-title>
    <v-container class="pa-0 mt-15px" fluid>
      <v-row class="mb-21px" dense>
        <div class="base-store">
          <span>基準チェーン：</span>
          <CustomSelector
            :key="selectedBaseChainItem?.value"
            v-model="selectedBaseChainItem"
            :items="selectedChainItems"
            width="300px"
            placeholder="基準チェーンを選択してください。"
            :disabled="props.selectedChainIds.length === 0"
          />
        </div>
      </v-row>
      <v-row class="my-0" dense>
        <v-col class="py-0 px-0">
          <div v-if="!props.initialBaseChainId" class="no-area-overlay">
            <span>基準となるチェーンを選択してください</span>
          </div>
          <LoadingImg v-else-if="tableAreasLoading" :height="'396px'" />
          <NoDataChainChart v-else-if="isNoData" />
          <BizAreaTable
            v-else
            :areas="tableAreas"
            :base-chain-id="selectedBaseChainItem?.value.toString()"
            :selected-chain-ids="props.selectedChainIds"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import { Chain } from '@/commons/interfaces'
import NoDataChainChart from '@/commons/components/Chart/NoDataChainChart.vue'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { Area, ComparisonTrendAreaAnalysisResponse } from '../types'
import BizAreaTable from './BizAreaTable.vue'
import { downloadBizAreaComparisonTable, getBizAreaComparisonTable } from '../axios'
import { GRANULARITY } from '@/commons/enums'
import * as notify from '@/plugins/notification'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import CustomSelector, { Item } from '@/commons/components/Elements/CustomSelector.vue'
import { toRef } from 'vue'
import { AxiosError } from 'axios'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { isEqualSorted } from '@/commons/utils/chain'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    selectedChainIds?: string[]
    selectedPrefectureIds?: number[]
    granularity?: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    filteredPrefectureId?: number
    filteredCityIds?: string[]
    alertAreaNames?: string[]
    initialBaseChainId?: string
    onBaseChainIdChange?: (id: string | undefined) => void
    onFilteredPrefectureIdChange?: (prefectureId: number | undefined) => void
    onFilteredCityIdsChange?: (cityIds: string[] | undefined) => void
  }>(),
  {
    selectedChainIds: (): string[] => [],
    selectedPrefectureIds: (): number[] => [],
    granularity: GRANULARITY.PREFECTURE,
    filteredPrefectureId: undefined,
    filteredCityIds: undefined,
    alertAreaNames: (): string[] => [],
    initialBaseChainId: undefined,
    onBaseChainIdChange: () => () => undefined,
    onFilteredPrefectureIdChange: () => undefined,
    onFilteredCityIdsChange: () => undefined
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)
const chainMap = computed<Map<string, Chain>>(() => store.getters.chainMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const selectedChainItems = computed<Item[]>(
  () =>
    props.selectedChainIds
      .map((id) => {
        const chain = chainMap.value.get(id)
        return chain ? { text: chain.name, value: chain.id } : undefined
      })
      .filter((chain) => !!chain) as Item[]
)

// select の v-model 用
const selectedBaseChainItem = ref<Item | undefined>(
  selectedChainItems.value.find(({ value }) => {
    return value === props.initialBaseChainId
  })
)

const tableAreasLoading = ref<boolean>(false)
const tableAreasResponse = ref<ComparisonTrendAreaAnalysisResponse | undefined>(undefined)
const tableAreas = computed<Area[]>(() => {
  if (!tableAreasResponse.value) return []
  return tableAreasResponse.value.areas
})
const tableAreasAlert = computed<boolean>(() => {
  if (!tableAreasResponse.value) return false
  return tableAreasResponse.value.areas.some(({ chains }) => chains.some(({ isAlert }) => isAlert))
})
const isNoData = computed<boolean>(() => {
  if (!tableAreasResponse.value) return false
  return tableAreasResponse.value.areas.length == 0
})

// 「エリアを絞り込み」が適応された場合は、結果を選択されたprefectureIdに,granularityをPREFECTURE(2)に限定する
const resolvePrefectureIdsAndGranularity = () => {
  const selectOrFileredPrefectureIds = props.filteredPrefectureId
    ? [props.filteredPrefectureId.toString()]
    : props.selectedPrefectureIds.map((id) => id.toString())

  const granularity = props.filteredPrefectureId ? props.granularity : GRANULARITY.PREFECTURE

  return { selectOrFileredPrefectureIds, granularity }
}

const fetchTableAreas = async () => {
  if (tableAreasLoading.value) return

  const { selectOrFileredPrefectureIds, granularity } = resolvePrefectureIdsAndGranularity()

  try {
    tableAreasLoading.value = true
    const res = await getBizAreaComparisonTable(
      selectedBaseChainItem.value?.value.toString(),
      props.selectedChainIds,
      selectOrFileredPrefectureIds,
      props.filteredCityIds,
      startMonth.value,
      endMonth.value,
      granularity
    )
    tableAreasResponse.value = res.data
  } catch (e: any) {
    if (e.code !== AxiosError.ERR_CANCELED)
      notify.notifyErrorMessage('商圏ランキングが表示できませんでした。')
  } finally {
    tableAreasLoading.value = false
  }
}

const clikDownloadBizAreaComparisonTable = () => {
  const { selectOrFileredPrefectureIds, granularity } = resolvePrefectureIdsAndGranularity()

  return downloadBizAreaComparisonTable(
    selectedBaseChainItem?.value?.value.toString(),
    props.selectedChainIds,
    selectOrFileredPrefectureIds,
    props.filteredCityIds,
    startMonth.value,
    endMonth.value,
    granularity
  )
}

// 商圏エリア別シェア獲得比率(β)_BizAreaTableContentsコンポーネント用
// Filterで選択されている都道府県・市区町村の値をリセット
const resetFilterValue = () => {
  if (props.onFilteredPrefectureIdChange) {
    props.onFilteredPrefectureIdChange(undefined)
  }

  if (props.onFilteredCityIdsChange) {
    props.onFilteredCityIdsChange(undefined)
  }
}

/* --------------------------------------------------------------------------
  created
 -------------------------------------------------------------------------- */

if (
  props.selectedChainIds.length > 0 &&
  props.selectedChainIds.includes(selectedBaseChainItem.value?.value.toString() ?? '')
) {
  fetchTableAreas()
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  selectedBaseChainItem,
  () => {
    props.onBaseChainIdChange(selectedBaseChainItem.value?.value.toString())
  },
  { deep: true }
)

watch(
  () => props.selectedChainIds,
  () => {
    // チェーンの数が 0 の場合は基準チェーンを未選択状態にする
    if (!props.selectedChainIds.length) {
      selectedBaseChainItem.value = undefined
    }

    // 基準チェーンに設定しているチェーンが選択 UI より解除された際、選択済みのチェーンの始めを基準チェーンに再設定する。
    if (
      !selectedBaseChainItem.value ||
      !props.selectedChainIds.includes(selectedBaseChainItem.value?.value.toString() ?? '')
    ) {
      selectedBaseChainItem.value =
        selectedChainItems.value.length > 0 ? selectedChainItems.value[0] : undefined
    }
  },
  { deep: true }
)

const unwatch = watch(
  () => [toRef(props.initialBaseChainId), selectedChainItems],
  () => {
    const initialSelectedChainItem = selectedChainItems.value.find(({ value }) => {
      return value === props.initialBaseChainId
    })

    selectedBaseChainItem.value = initialSelectedChainItem

    if (initialSelectedChainItem) {
      unwatch()
    }
  },
  { deep: true }
)

watch(
  () => [
    selectedBaseChainItem.value,
    props.selectedChainIds,
    props.selectedPrefectureIds,
    props.granularity,
    startMonth.value,
    endMonth.value,
    props.filteredPrefectureId,
    props.filteredCityIds
  ],
  (newValues, oldValues) => {
    if (
      props.selectedChainIds.length > 0 &&
      props.selectedChainIds.includes(selectedBaseChainItem.value?.value.toString() ?? '') &&
      !isEqualSorted(newValues, oldValues)
    ) {
      fetchTableAreas()
    }
  },
  { deep: true }
)

watch(tableAreasResponse, (value) => {
  if (value && props.selectedChainIds.length <= 0) {
    tableAreasResponse.value = undefined
  }
})
</script>

<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0;
}
.mb-21px {
  margin-bottom: 21px;
}
.mr-27px {
  margin-right: 27px;
}
.mt-15px {
  margin-top: 15px;
}
.font-bold {
  font-weight: bold;
}
.card-container {
  height: 100%;
  .no-area-overlay {
    background: #eeeeee;
    width: 100%;
    height: 100%;
    min-height: 396px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card-top {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.biz-area-table-title {
  display: flex;
  b {
    margin-right: 8px;
    font: normal normal bold 14px/30px Noto Sans JP;
  }
}
.base-store {
  display: flex;
  align-items: center;
  text-align: left;
  font: normal normal normal 14px/30px Noto Sans JP;
  color: #666666;
}
.v-row--dense {
  margin-top: initial;
  margin-right: initial;
  margin-left: initial;
}
</style>
