<template>
  <v-card height="'100%'" class="chain-selector-card">
    <v-container fluid class="pa-0">
      <div class="chain-selector-card-title">
        <span>比較したいチェーンを選んでください（最大6チェーン）。</span>
        <PrefectureDialog
          :prefectures="filteredPrefectures.filter((prefecture) => prefecture.storeCount > 0)"
          :filter="searchFilters"
          :handle-filter="handleFilter"
        />
      </div>
      <LoadingImg v-if="isLoading" height="44px" />
      <div v-else>
        <AutoComplete
          :multiple="true"
          :chains="chainsWithOpenStores()"
          :selected-ids="selectedChains.map(({ id }) => id)"
          :select-limit="SELECT_LIMIT"
          :handle-update-chain="handleUpdateChain"
        />
        <div class="selected-chain-wrapper">
          <div v-for="chain in selectedChains" :key="chain.id" class="selected-chain">
            <div>
              <span class="dot" :style="{ color: chainIdToColorMap.get(chain.id) }">●</span>
              {{ chain.name }}
              （
              {{
                chain.stores.filter((store) =>
                  searchFilters.prefectureIds.includes(store.prefectureId)
                ).length
              }}店舗 ）
            </div>
            <button
              class="mt-2px"
              :aria-label="chain.name + '削除ボタン'"
              @click="handleUpdateChain(chain)"
            >
              <img src="@/assets/svg/close-middle.svg" class="cursor-pointer" />
            </button>
          </div>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import AutoComplete from './AutoComplete.vue'
import { Chain } from '@/commons/interfaces'
import { Store } from '@/commons/interfaces/responses/store'
import PrefectureDialog from './PrefectureDialog.vue'
import { ChainFilter, OpenCloseDict } from './types'
import { COLOR } from '@/commons/enums'
import { useStore } from 'vuex'
import { Prefecture, PrefectureWithStoreCount } from '@/features/Dashboard/types'
import { getPrefecturesWithStoreCount } from '@/commons/utils/prefecture'
import LoadingImg from '../loadingImg.vue'
import { useRoute } from 'vue-router'
import { isEqual, cloneDeep } from 'lodash'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    initialChainIds?: string[]
    initialPrefectureIds?: number[]
    startDate: string
    endDate: string
    shouldUpdateVuex?: boolean
    handleUpdate: (chains: Chain[], prefectureIds: number[]) => void
  }>(),
  {
    initialChainIds: (): string[] => [],
    initialPrefectureIds: (): number[] => [],
    startDate: '',
    endDate: '',
    shouldUpdateVuex: true,
    handleUpdate: () => undefined
  }
)

/* --------------------------------------------------------------------------
  const
 ---------------------------------------------------------------------------*/

const COLORS = [COLOR.RED, COLOR.BLUE, COLOR.GREEN, COLOR.ORANGE, COLOR.PURPLE, COLOR.BROWN]
const SELECT_LIMIT = 6

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const chains = computed<Chain[]>(() => store.getters.chains)
const prefectures = computed<Prefecture[]>(() => store.state.prefectures)

/* --------------------------------------------------------------------------
  core logic
 ---------------------------------------------------------------------------*/

// loading
const isLoading = ref<boolean>(true)

// 選択済みチェーン一覧
const selectedChains = ref<Chain[]>([])

// 検索条件フィルター
const searchFilters = ref<ChainFilter>({ prefectureIds: [] })

// 検索したチェーンのチップで使用するカラーマップ
const chainIdToColorMap = ref<Map<string, string>>(new Map())

// 店舗一覧と都道府県から各都道府県の店舗数を追加した都道府県データ
const filteredPrefectures = computed<PrefectureWithStoreCount[]>(() => {
  if (selectedChains.value.length === 0) return []
  return getPrefecturesWithStoreCount(
    prefectures.value,
    selectedChains.value.flatMap((chain) => chain.stores)
  )
})

// フィルタリング関数
const handleFilter = (filter: ChainFilter) => {
  searchFilters.value.prefectureIds = filter.prefectureIds
  if (selectedChains.value.length > 0) {
    props.handleUpdate(selectedChains.value, filter.prefectureIds)
  }

  if (props.shouldUpdateVuex) {
    store.commit('setSelectedPrefectureIdsOfChains', {
      prefectureIds: filter.prefectureIds
    })
  }
}

// チェーン更新関数
const handleUpdateChain = (chain: Chain) => {
  if (selectedChains.value.some((selected) => selected.id === chain.id)) {
    selectedChains.value = selectedChains.value.filter((selected) => selected.id !== chain.id)
    chainIdToColorMap.value.delete(chain.id)
  } else {
    if (selectedChains.value.length >= SELECT_LIMIT) return

    selectedChains.value = [...selectedChains.value, chain]
    chainIdToColorMap.value.set(
      chain.id,
      COLORS.find((color) => !Array.from(chainIdToColorMap.value.values()).includes(color)) ??
        COLORS[0]
    )
  }
  const newPrefectureIds = prefectures.value
    .map(({ prefectureId }) => prefectureId)
    .filter((id) =>
      selectedChains.value
        .flatMap((chain) => chain.stores)
        .some(({ prefectureId }) => prefectureId === id)
    )
  searchFilters.value.prefectureIds = newPrefectureIds

  props.handleUpdate(selectedChains.value, newPrefectureIds)

  if (props.shouldUpdateVuex) {
    store.commit('setSelectedChainIds', {
      chainIds: selectedChains.value.map(({ id }) => id)
    })
    store.commit('setSelectedPrefectureIdsOfChains', {
      prefectureIds: newPrefectureIds
    })
    store.commit('setChainIdToColorMap', {
      map: chainIdToColorMap
    })
  }
}

// 日付が範囲内に収まるかどうかを判定
const isDateInRange = (dateRange: OpenCloseDict, startDate: Date, endDate: Date) => {
  const lowerDate = dateRange.lower ? new Date(dateRange.lower) : null
  const upperDate = dateRange.upper ? new Date(dateRange.upper) : null

  return (
    (lowerDate === null || lowerDate <= endDate) && (upperDate === null || upperDate >= startDate)
  )
}

// 特定のチェーンの店舗が指定期間内で開店しているかを判定
const filterOpenStores = (stores: Store[], startDate: Date, endDate: Date) => {
  return stores.filter((store) =>
    store.openCloseDates.some((dateRange) => isDateInRange(dateRange, startDate, endDate))
  )
}

// 指定期間に開店しているチェーンと店舗を返す関数
const chainsWithOpenStores = () => {
  const startDate = new Date(props.startDate)
  const endDate = new Date(props.endDate)

  // 深いコピーを作成し、チェーンごとに店舗をフィルタリング
  const filteredChains = cloneDeep(chains.value).map((chain) => {
    const openStores = filterOpenStores(chain.stores, startDate, endDate)
    return { ...chain, stores: openStores }
  })

  // 選択済みチェーンがあれば内容を更新
  if (selectedChains.value.length > 0) {
    const selectedChainIdsArray = selectedChains.value.map((chain) => chain.id)

    // 選択済みチェーンの中で、フィルタされたチェーンに該当するものを取得
    const selectedChainInFilteredChains = filteredChains.filter((chain) =>
      selectedChainIdsArray.includes(chain.id)
    )

    // 選択済みチェーンの店舗とフィルタ後の店舗を比較
    const selectedChainStores = selectedChains.value.flatMap((chain) => chain.stores)
    const selectedChainInFilteredChainStores = selectedChainInFilteredChains.flatMap(
      (chain) => chain.stores
    )

    // 店舗が異なっていれば更新
    if (!isEqual(selectedChainStores, selectedChainInFilteredChainStores)) {
      selectedChains.value = selectedChainInFilteredChains
    }
  }

  return filteredChains
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

const route = useRoute()
const handleCreated = async () => {
  isLoading.value = true

  try {
    // チェーン(生成元の店舗)が未取得の場合は店舗を取得
    if (!chains.value.length) await store.dispatch('fetchStores')

    // 都道府県が未取得の場合は都道府県を取得
    if (!prefectures.value.length) await store.dispatch('fetchPrefectures')

    // チェーンの初期値が設定されている場合は選択済みチェーンを更新
    if (route.query.chainIds) {
      const queryChainIds = Array.isArray(route.query.chainIds)
        ? route.query.chainIds
        : [route.query.chainIds]
      selectedChains.value = queryChainIds
        .map((id) => chains.value.find((chain) => chain.id === id))
        .filter((chain): chain is Chain => Boolean(chain))

      props.initialChainIds.forEach((id) => {
        chainIdToColorMap.value.set(
          id,
          COLORS.find((color) => !Array.from(chainIdToColorMap.value.values()).includes(color)) ??
            COLORS[0]
        )
      })
    }

    // 都道府県の初期値が設定されている場合は選択済みの都道府県を更新
    if (route.query.prefectureIds && Array.isArray(route.query.prefectureIds)) {
      searchFilters.value = {
        ...searchFilters.value,
        prefectureIds: route.query.prefectureIds.map((a) => Number(a))
      }
    }

    // Vuex を更新する場合は初期値のチェーンと都道府県、複数チェーン用のカラーマップを更新
    if (props.shouldUpdateVuex) {
      if (props.initialChainIds) {
        store.commit('setSelectedChainIds', { chainIds: props.initialChainIds })
      }

      if (props.initialPrefectureIds) {
        store.commit('setSelectedPrefectureIdsOfChains', {
          prefectureIds: props.initialPrefectureIds
        })
      }

      store.commit('setChainIdToColorMap', {
        map: chainIdToColorMap
      })
    }

    // 都道府県の初期値が設定されている場合は選択済みの都道府県を更新
    if (props.initialPrefectureIds.length > 0) {
      searchFilters.value = { ...searchFilters.value, prefectureIds: props.initialPrefectureIds }
    }
  } finally {
    isLoading.value = false
  }
}

handleCreated()

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => [props.startDate, props.endDate],
  () => {
    chainsWithOpenStores()
  }
)
</script>

<style scoped>
.chain-selector-card {
  padding: 23px 30px 29px !important;
}

.chain-selector-card-title {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
}

.chain-selector-card-title a {
  color: #4d99d0;
}

.selected-chain-wrapper {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-chain {
  height: 42px;
  width: fit-content;
  padding: 12px 14px 13px 13px;
  font-size: 14px;
  font-weight: bold;
  text-align: 25px;
  color: #333;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.dot {
  margin-right: 4px;
}
.cursor-pointer {
  cursor: pointer;
}
.mt-2px {
  margin-top: 2px;
}
</style>
